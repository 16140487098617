.hp-avantages {
  background-color: #fff;
}
.hp-avantages__container {
  --vertical-space: var(--space-40);
  width: 70%;
  justify-content: center;
  display: grid;
  grid-template: "header content" 1fr "footer content" 1fr/1fr 1fr;
  gap: 1.75rem;
  margin-inline: auto;
  padding-block: var(--vertical-space);
}
@media (max-width: 1023px) {
  .hp-avantages__container {
    --vertical-space: var(--space-20);
    grid-template-rows: auto;
    grid-template-areas: "header header" "content content" "footer footer";
  }
}
@media (min-width: 1024px) {
  .hp-avantages__container {
    width: 75%;
  }
}
.hp-avantages__header {
  grid-area: header;
}
.hp-avantages__header .h2 {
  margin-bottom: var(--space-32);
  max-width: 400px;
  margin-top: 1rem;
}
.hp-avantages__content {
  grid-area: content;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-20);
}
.hp-avantages__footer {
  grid-area: footer;
}
@media (max-width: 1023px) {
  .hp-avantages__footer {
    display: flex;
    justify-content: center;
    margin: var(--vertical-space) auto;
  }
}
.hp-avantages__text {
  --text-fs: 1.125rem;
  font-weight: 500;
  color: var(--color-blue-1100);
  max-inline-size: 200px;
  font-size: var(--text-fs);
}
@media (max-width: 1023px) {
  .hp-avantages__text {
    --text-fs: 0.875rem;
  }
}
.hp-avantages__text > p {
  display: inline;
  line-height: normal;
}
.hp-avantages__text > p + p {
  display: block;
}